/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://cloud.google.com/blog/ko/topics/google-cloud-next/welcome-to-google-cloud-next-23"
  }, "썸네일 원본")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://cloud.google.com/blog/ko/topics/google-cloud-next/welcome-to-google-cloud-next-23"
  }, "구글 클라우드 넥스트 2023")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://cloud.google.com/blog/topics/google-cloud-next/next-2023-wrap-up"
  }, "구글 클라우드 넥스트 Warp Up")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://cloud.withgoogle.com/next/hub"
  }, "오프닝 키노트")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://cloud.google.com/blog/products/ai-machine-learning/vertex-ai-search-and-conversation-is-now-generally-available"
  }, "Vertex AI Search")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://cloud.google.com/generative-ai-studio"
  }, "Gen AI Studio")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://cloud.google.com/solutions/cross-cloud-network"
  }, "Cross-Cloud Network")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://cloud.google.com/spanner"
  }, "Cloud Spanner")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://cloud.google.com/duet-ai"
  }, "Duet AI")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://workspace.google.com/blog/product-announcements/duet-ai-in-workspace-now-available"
  }, "Duet AI for Google Workspace")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://workspace.google.com/blog/product-announcements/duet-ai-in-workspace-now-available"
  }, "Duet AI in Google Meet")), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://cloud.google.com/tpu"
  }, "Google Cloud TPU")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://cloud.google.com/blog/products/maps-platform/going-beyond-map-introducing-environment-apis"
  }, "Environment APIs")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://cloud.google.com/blog/products/maps-platform/powering-future-our-new-solar-api"
  }, "solar")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://cloud.google.com/blog/products/maps-platform/announcing-pollen-api-providing-actionable-info-about-airborne-pollen-levels-worldwide"
  }, "air quality")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://cloud.google.com/blog/products/maps-platform/introducing-air-quality-api-promoting-resilience-changing-climate"
  }, "pollen")), "\n"), "\n"), "\n");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
